import { render, staticRenderFns } from "./findprecedent_result.vue?vue&type=template&id=a8b29852&scoped=true"
import script from "./findprecedent_result.vue?vue&type=script&lang=js"
export * from "./findprecedent_result.vue?vue&type=script&lang=js"
import style0 from "./findprecedent_result.vue?vue&type=style&index=0&id=a8b29852&prod&scoped=true&lang=css"
import style1 from "./findprecedent_result.vue?vue&type=style&index=1&id=a8b29852&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b29852",
  null
  
)

export default component.exports